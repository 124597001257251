
    import { defineComponent, PropType } from 'vue';
    import Datepicker from './datepicker/Datepicker.vue'

    export default defineComponent({
        components: {
            Datepicker
        },
        emits: ['update:modelValue', 'update:date'],
        props: {
            modelValue: String,
            date: {
                type: [Date],
                required: false,
                default: new Date()
            },
            errors: Boolean,
            label: String,
            id: String,
            values: String,
            rules: String,
            readonly: {
                type: Boolean,
                required: false,
                default: false
            },
            disabledDates: {
                type: Object as PropType<{ dates?: Date[], predicate?: (currentDate: Date) => boolean }>,
                required: false,
            },
        },
        data() {
            return {
                errorMessage: '',
                value: this.date
            }
        },
        mounted() {
        },
        methods: {
        },

        watch: {
            value: function (val: any) {
                this.$emit('update:modelValue', val.toString());
                this.$emit('update:date', val);
            }
        }
    });
