
    import { defineComponent } from 'vue';
    import AppSelector from '@/components/widgets/AppSelector.vue';

    export default defineComponent({
        components: {
            AppSelector
        },
        data: function() {
            return {
            }
        },
    });
