import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3d21ce5c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "col"
}
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = {
  key: 1,
  class: "col"
}
const _hoisted_6 = { class: "row" }
const _hoisted_7 = { class: "col" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { class: "row" }
const _hoisted_10 = { class: "col" }
const _hoisted_11 = { class: "fw-bolder" }
const _hoisted_12 = {
  key: 1,
  class: "col"
}
const _hoisted_13 = {
  key: 2,
  class: "col"
}
const _hoisted_14 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceContract.allFields, (step, stepIndex) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "row",
      key: stepIndex
    }, [
      (!step.canBeArray)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                _createElementVNode("h5", null, _toDisplayString(_ctx.serviceContract.getName(stepIndex)), 1 /* TEXT */)
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceContract.allFields[stepIndex].sections, (section, zi) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "row",
                key: zi
              }, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.columns, (column, xi) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: _normalizeClass(["col mb-4 sectionColumn m-1", column.fields.length > 0 ? 'border' : '']),
                    key: xi
                  }, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(column.fields, (field, index) => {
                      return (_openBlock(), _createElementBlock("div", {
                        class: "row",
                        key: index
                      }, [
                        _createElementVNode("div", {
                          class: _normalizeClass(["fw-bold", field.type == 'textarea' ? 'col-12' : 'col'])
                        }, _toDisplayString(field.label), 3 /* TEXT, CLASS */),
                        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.getRealValue(field)), 1 /* TEXT */)
                      ]))
                    }), 128 /* KEYED_FRAGMENT */))
                  ], 2 /* CLASS */))
                }), 128 /* KEYED_FRAGMENT */))
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
        : (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", _hoisted_6, [
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("h5", null, _toDisplayString(_ctx.serviceContract.getName(stepIndex)), 1 /* TEXT */)
              ])
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceContract.allFields[stepIndex].values, (value, vi) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "row p-4 border mb-4",
                key: vi
              }, [
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("h6", _hoisted_11, _toDisplayString(_ctx.serviceContract.getName(stepIndex)) + " " + _toDisplayString(vi + 1), 1 /* TEXT */)
                    ])
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value, (section, zi) => {
                    return (_openBlock(), _createElementBlock("div", {
                      class: "row",
                      key: zi
                    }, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(section.columns, (column, xi) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: _normalizeClass(["col mb-4 sectionColumn m-1", column.fields.length > 0 ? 'border' : '']),
                          key: xi
                        }, [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(column.fields, (field, index) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "row",
                              key: index
                            }, [
                              (field.type != 'serviceTask')
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 0,
                                    class: _normalizeClass(["fw-bold", field.type == 'textarea' ? 'col-12' : 'col'])
                                  }, _toDisplayString(field.label), 3 /* TEXT, CLASS */))
                                : _createCommentVNode("v-if", true),
                              (field.type != 'serviceTask')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_12, _toDisplayString(_ctx.getRealValue(field)), 1 /* TEXT */))
                                : _createCommentVNode("v-if", true),
                              (field.type == 'serviceTask')
                                ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                                    _createElementVNode("div", _hoisted_14, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.serviceContract.serviceTaskFields, (taskField, fi) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          class: "col fw-bold",
                                          key: fi
                                        }, _toDisplayString(taskField.label), 1 /* TEXT */))
                                      }), 128 /* KEYED_FRAGMENT */))
                                    ]),
                                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(field.value, (row, rowi) => {
                                      return (_openBlock(), _createElementBlock("div", {
                                        class: "row",
                                        key: rowi
                                      }, [
                                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row, (val, vali) => {
                                          return (_openBlock(), _createElementBlock("div", {
                                            class: "col border",
                                            key: vali
                                          }, _toDisplayString(_ctx.getRealValue(_ctx.createFakeField(vali, val, row))), 1 /* TEXT */))
                                        }), 128 /* KEYED_FRAGMENT */))
                                      ]))
                                    }), 128 /* KEYED_FRAGMENT */))
                                  ]))
                                : _createCommentVNode("v-if", true)
                            ]))
                          }), 128 /* KEYED_FRAGMENT */))
                        ], 2 /* CLASS */))
                      }), 128 /* KEYED_FRAGMENT */))
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ]))
            }), 128 /* KEYED_FRAGMENT */))
          ]))
    ]))
  }), 128 /* KEYED_FRAGMENT */))
}