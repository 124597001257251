
    import { defineComponent } from 'vue';
    
    import TextInput from '@/components/inputs/TextInput.vue'
    import NumberInput from '@/components/inputs/NumberInput.vue'
    import DropdownInput from '@/components/inputs/DropdownInput.vue'
    import { Field } from '../helpers/Fields.interface'


    export default defineComponent({
        emits: ['deleteRow'],
        components: {
            TextInput,
            NumberInput,
            DropdownInput,
        },
        props: {
            serviceContract: {
                type: Object,
                required: true
            },
            taskRow: {
                type: Object,
                required: true
            },
            deletable: {
                type: Boolean,
                required: false,
                default: false
            },
        },
        data: function() {
            return {
                fields: {} as Field[],
                price: 0
            }
        },
        methods: {
            deleteRow() {
                this.$emit('deleteRow');
            },

            getPrice(priceObj: any) {
                if (!priceObj || !priceObj.SalesPrice) {
                    return;
                }

                this.price = parseFloat(priceObj.SalesPrice);
            },

            getFieldKey(fieldId: string | undefined) {
                if (!fieldId)
                    return 0;

                return fieldId?.split('.')[1];
            },

            isReady(fieldId: string | undefined) {
                const fieldKey = this.getFieldKey(fieldId);

                switch (fieldKey) {
                    case 'TaskDescriptionID':
                        return true;
                    case 'ProductCatalogPriceListID':
                        return this.taskRow.TaskDescriptionID != null;
                    case 'Area':
                        return this.taskRow.ProductCatalogPriceListID != null;
                    case 'VisitsPerYear':
                        return this.taskRow.ProductCatalogPriceListID != null;
                    case 'HoursPerVisit':
                        return this.taskRow.ProductCatalogPriceListID != null;
                    case 'Product':
                        return this.taskRow.ProductCatalogPriceListID != null;
                    case 'SalesPrice':
                        return this.taskRow.ProductCatalogPriceListID != null;
                }
            },

            getDropdownKey(fieldId: string | undefined) {
                const fieldKey = this.getFieldKey(fieldId);

                
                switch (fieldKey) {
                    case 'ProductCatalogPriceListID':
                        return 'ServiceDescription';
                }

                return false;
            },

            prepareValues(values: any) {
                let rowValues = values.slice();
                rowValues.match(/{([^}]+)}/g)?.forEach((match: any) => {
                    const fieldValue = this.taskRow[match.replace('{', '').replace('}', '')];
                    if (fieldValue) {
                        rowValues = rowValues.replace(match, fieldValue);
                    }
                });
                return rowValues;
            },

            calulatePrice() {
                let totalPrice = 0;

                const productCount = (/^[0-9]+$/.test(this.taskRow.Product)) ? parseInt(this.taskRow.Product) : 0;

                if (productCount > 0)
                {
                    totalPrice = productCount * this.price;
                } else {
                    const visitCount = (/^[0-9]+$/.test(this.taskRow.VisitsPerYear)) ? parseInt(this.taskRow.VisitsPerYear) : 0;
                    const hoursCount = (/^[0-9]+$/.test(this.taskRow.HoursPerVisit)) ? parseInt(this.taskRow.HoursPerVisit) : 0;

                    totalPrice = hoursCount * visitCount * this.price;
                }

                this.taskRow.SalesPrice = totalPrice.toString();

            }
        },
        mounted() {
            const fields: Field[] = JSON.parse(JSON.stringify(this.serviceContract.serviceTaskFields));
            this.fields = fields;
        },
        watch: {
            "taskRow.TaskDescriptionID": function (newVal) {
                    this.taskRow.ProductCatalogPriceListID = null;
                    this.taskRow.Area = null;
                    this.taskRow.VisitsPerYear = null;
                    this.taskRow.HoursPerVisit = null;
                    this.taskRow.Product = null;
                    this.taskRow.SalesPrice = "0";
                    this.calulatePrice();
            },

            "taskRow.ProductCatalogPriceListID": function () {
                this.calulatePrice();
            },

            "taskRow.VisitsPerYear": function () {
                this.calulatePrice();
            },
            
            "taskRow.HoursPerVisit": function () {
                this.calulatePrice();
            },

            "taskRow.Product": function () {
                this.calulatePrice();
            }

        },
    });
