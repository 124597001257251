
    import { defineComponent } from 'vue';
    import LaravelPagination from '@/components/widgets/LaravelPagination/LaravelPagination.vue'

    export default defineComponent({
        components: {
            LaravelPagination,
        },
        emits: ['ok', 'cancel'],
        props: {
            excludedKeys: Array,
            primaryKey: String,
            text: String,
            title: String,
            okBtn: String,
            searchPath: String,
            cancelBtn: String,
            enableX: {
                type: Boolean,
                default: true
            }
        },
        data: function() {
            return {
                searchString: '',
                config: {
                    url: this.searchPath,
                    method: 'get',
                    query: this.searchString,
                    excludedKeys: this.excludedKeys
                },
                selected: null,
            }
        },
        methods: {
            okPressed() {
                if (this.primaryKey) {
                    this.$emit('ok', (this as any).selected[this.primaryKey]);
                    return;
                }
                this.$emit('ok', null);
            },

            setSelected(selected: any) {
                this.selected = selected;
            },
        },
        mounted() {
        },
    });
