
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {
        },
        emits: ['update:modelValue', 'update:errors'],
        props: {
            modelValue: String,
            errors: Boolean,
            label: String,
            id: String,
            values: String,
            readonly: {
                type: Boolean,
                required: false,
                default: false
            },
            rules: String
        },
        mounted() {
            this.getOptions();
        },
        data() {
            return {
                options: {},
                errorMessage: ''
            }
        },
        methods: {
            async handleBlur() {
                this.$root?.validateField(this.label ?? '', this.modelValue ?? '', this.rules ?? '').then((validation: any) => {
                    this.$emit('update:errors', validation.errors);
                    this.errorMessage = validation.message;
                });
            },

            getOptions() {
                const config = {
                    url: this.values,
                    method: 'get',
                }
                this.$root?.$data.API.request(config).then((response: any) => {
                    this.options = response.data;
                })
                .catch((error: any) => {
                    this.options = {'Fejl': 'Kunne ikke hente liste'};
                    console.error(error);
                })
            },

            handleInput(event: any) {
                this.$emit('update:modelValue', event.target.value);
            }
        },
    });
