
import { defineComponent } from 'vue';
import { Field, stringArray } from '../helpers/Fields.interface';

    export default defineComponent({
        components: {
        },
        props: {
            serviceContract: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                descriptionText: '',
                valueCache: {} as stringArray
            }
        },
        mounted() {
        },
        methods: {

            getRealValue(field: Field) {
                if (field.type == 'dropdown') {
                    if (field.value == '' || field.loading == true) {
                        return field.fakeValue;
                    }
                    field.fakeValue = 'Henter værdi...';
                    field.loading = true;
                    const config = {
                        url: field.values,
                        method: 'get',
                    }

                    const hash: string = btoa(field.values ?? '');
                    if (this.valueCache[hash]) {
                        console.log('Value exists');
                        return this.valueCache[hash as keyof Object];
                    } else {
                        this.valueCache[hash] = 'Henter værdi...';
                    }

                    this.$root?.$data.API.request(config).then((response: any) => {
                        field.fakeValue = response.data[field.value as string];
                        const data = response.data;
                        console.log(data);
                        if (typeof(data[field.value as string]) == 'string') {
                            field.fakeValue = data[field.value as string];
                        } else {
                            field.fakeValue = data[field.value as string].ServiceDescription;
                        }
                        this.valueCache[hash] = field.fakeValue?? '';
                    })
                    .catch((error: any) => {
                        field.fakeValue = 'Kunne ikke hente værdi';
                    })
                    return field.fakeValue;
                } else {
                    return field.value;
                }

            },

            prepareValues(values: string, taskRow: any): string {
                let rowValues = values.slice();
                rowValues.match(/{([^}]+)}/g)?.forEach((match: any) => {
                    const fieldValue = taskRow[match.replace('{', '').replace('}', '')];
                    if (fieldValue) {
                        rowValues = rowValues.replace(match, fieldValue);
                    }
                });
                return rowValues;
            },

            createFakeField(fieldId: any, fieldValue: string, taskRow: any): Field {
                const index: number = this.serviceContract.serviceTaskFields.findIndex((f: Field) => f.id?.match(fieldId));
                if (index != -1) {
                    const field: Field = JSON.parse(JSON.stringify(this.serviceContract.serviceTaskFields[index]));
                    field.value = fieldValue;
                    field.values = this.prepareValues(field.values ?? '', taskRow);
                    return field;
                }

                console.log('nooo');

                const fakeField: Field = {
                    id: 'fakeField',
                    value: 'Noget gik galt!',
                    type: 'text',
                    errors: true
                }

                return fakeField;
                
            },
            getOptions(url: string) {
                
            },
        },
    });
