
    import { defineComponent } from 'vue';

    export default defineComponent({

        components: {
        },
        props: {
            description: String,
            id: String,
            field: String,
        },
        data() {
            return {
                descriptionText: '',
                isLoading: false,
            }
        },
        mounted() {
            this.getDescription();
        },
        methods: {
            getDescription() {
                if (!this.id) {
                    this.descriptionText = '';
                    return;
                }

                this.descriptionText = ''
                this.isLoading = true;

                const config: any = {
                    url: this.description,
                    method: 'get',
                    params: {}
                }
                config.params[this.field?.split('.').pop() ?? 'id'] = this.id;

                this.$root?.$data.API.request(config).then((response: any) => {
                    this.isLoading = false;
                    this.descriptionText = response.data.Description;
                })
                .catch((error: any) => {
                    this.isLoading = false;
                    this.descriptionText = 'Fejl: Kunne ikke hente beskrivelse';
                    console.error(error);
                })
            },
        },
        watch: {
            id: function() {
                this.getDescription();
            }
        }
    });
