
import { isValid } from 'date-fns'
import { defineComponent, PropType } from 'vue'

type Item = {
  key: string
  value: Date
  display: number | string
  disabled: boolean
  selected: boolean
}

export default defineComponent({
  emits: {
    elementClick: (value: Date) => isValid(value),
    left: () => true,
    right: () => true,
    heading: () => true,
  },
  props: {
    headingClickable: {
      type: Boolean,
      default: false,
    },
    leftDisabled: {
      type: Boolean,
      default: false,
    },
    rightDisabled: {
      type: Boolean,
      default: false,
    },
    columnCount: {
      type: Number,
      default: 7,
    },
    items: {
      type: Array as PropType<Item[]>,
      default: (): Item[] => [],
    },
  },
})
