import { createApp } from 'vue'
import App from './App.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import axios from 'axios'
import VueAxios from 'vue-axios'
import router from './routes/router';
import { BootstrapIconsPlugin } from 'bootstrap-icons-vue';
import VueAwesomePaginate from "vue-awesome-paginate";
import { msalPlugin } from "@/plugins/msalPlugin";
import { msalInstance } from "@/authConfig";
import { CustomNavigationClient } from "@/routes/NavigationClient";
import { refreshToken, getAccessToken, isTokenExpired, handleAuthenticationEvent } from '@/plugins/tokenHandler';
import mitt from 'mitt';
const emitter = mitt();

const navigationClient = new CustomNavigationClient(router);
msalInstance.setNavigationClient(navigationClient);

const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
    if (isTokenExpired()) {
        getAccessToken();
    } else {
        refreshToken();
    }
}
msalInstance.addEventCallback((event: any) => {
    handleAuthenticationEvent(event);
});

const clickOutside = {
    beforeMount: (el: any, binding: any) => {
      el.clickOutsideEvent = (event: any) => {
        if (!(el == event.target || el.contains(event.target))) {
          if (typeof(binding.value) == 'object') {
            binding.value.handler(binding.value.value);
          }
          else {
            binding.value();
          }
        }
      };
      document.addEventListener("click", el.clickOutsideEvent);
    },
    unmounted: (el: any) => {
      document.removeEventListener("click", el.clickOutsideEvent);
    },
  };


const app = createApp(App);
app.use(router);
app.use(VueAxios, axios)
app.use(VueAwesomePaginate)
app.use(BootstrapIconsPlugin)
app.use(msalPlugin, msalInstance);
app.directive("click-outside", clickOutside)
app.config.globalProperties.emitter = emitter
router.isReady().then(() => {
    app.mount('#app');
});