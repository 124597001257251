import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-floating mb-3" }
const _hoisted_2 = ["id", "readonly", "disabled", "placeholder", "value"]
const _hoisted_3 = ["for"]
const _hoisted_4 = { id: "datalistOptions" }
const _hoisted_5 = ["value"]
const _hoisted_6 = { class: "invalid-feedback" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("input", {
      list: "datalistOptions",
      class: _normalizeClass(["form-control", {'is-invalid': _ctx.errors}]),
      id: _ctx.id,
      readonly: _ctx.readonly,
      disabled: _ctx.readonly,
      placeholder: _ctx.label,
      value: _ctx.modelValue,
      onBlur: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleBlur && _ctx.handleBlur(...args))),
      onInput: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
    }, null, 42 /* CLASS, PROPS, HYDRATE_EVENTS */, _hoisted_2),
    _createElementVNode("label", {
      for: _ctx.id,
      class: "form-label"
    }, _toDisplayString(_ctx.label), 9 /* TEXT, PROPS */, _hoisted_3),
    _createElementVNode("datalist", _hoisted_4, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (id, name) => {
        return (_openBlock(), _createElementBlock("option", {
          value: id,
          key: id
        }, _toDisplayString(name), 9 /* TEXT, PROPS */, _hoisted_5))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.errorMessage), 1 /* TEXT */)
  ]))
}