import axios, { AxiosInstance } from "axios";

export default class API {

    _version: string;
    _client: AxiosInstance;
    _token: string;
    _baseUrl: string;

    constructor() {
        this._version = 'v1';

        this._token = 'no-token-set';
        this._baseUrl = 'https://ax-app-api.rapps.dk/api/'+this._version+'/';
        this._client = axios.create({
            baseURL: this._baseUrl,
            headers: {
                "Content-type": "application/json",
            },
        });
    }

    request(configArguments: {}): Promise<any> {
        this._setToken();
        const config = {...{
            headers: {
                'Authorization': "Bearer " + this._token
            }
        }, ...configArguments};

        return this._client.request(config);
            
    }

    _setToken() {
        this._token = localStorage.getItem('accessToken') ?? '';
    }

    path(path: string) {
        return this._baseUrl+path;
    }
}