import { msalInstance, loginRequest } from "../authConfig";
const refeshTokenTimeout: any = null;
import { AuthenticationResult, EventType } from "@azure/msal-browser";
import { ConsoleHttpPipelineLogger } from "@azure/core-http/types/latest/src/httpPipelineLogger";

export function refreshToken() {
    if (!isTokenExpired()) {
        const exp = msalInstance.getActiveAccount()?.idTokenClaims?.exp ?? 0;
        console.log('Fetch now access token in', (exp - ((Math.floor(Date.now()/1000))) - 300), ' seconds')
        if (refeshTokenTimeout) {
            clearTimeout(refeshTokenTimeout);
        }
        setTimeout(() => {
            getAccessToken();
        }, (exp - ((Math.floor(Date.now()/1000))) - 300)*1000);
    } else {
        console.log('Token expired, please login again');
    }
}

export function getAccessToken() {
    console.log('Get new access token');
    const account = msalInstance.getAllAccounts()[0];

    console.log('All accounts', msalInstance.getAllAccounts());
    const accessTokenRequest = {
        scopes: ['User.Read'],
        account: account,
        forceRefresh: true
    };
    msalInstance
        .acquireTokenSilent(accessTokenRequest)
        .then(function (payload) {
            _handleAuthenticationPayload(payload);
        })
        .catch(function (error) {
            console.log('Redirect to login');
            msalInstance.loginRedirect();
            console.error(error);
        });
}

export function isTokenExpired() {
    const exp = msalInstance.getActiveAccount()?.idTokenClaims?.exp;
    if (exp && exp > (Math.floor(Date.now()/1000))) {
        return false;
    }
    return true;
}

export function handleAuthenticationEvent(event: any) {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        _handleAuthenticationPayload(event.payload as AuthenticationResult);
    }
}

function _handleAuthenticationPayload(payload: AuthenticationResult) {
    console.log('Got new token', payload);
    const account = payload.account;
    console.log('new account', account);
    msalInstance.setActiveAccount(account);
    localStorage.setItem('accessToken', payload.idToken);
    refreshToken();
}