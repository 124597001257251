
    import { defineComponent } from 'vue';
    import AppList from '@/helpers/AppList';

    export default defineComponent({
        components: {
        },
        props: {
            smallText: {
                type: Boolean,
                default: false
            }
        },
        data: function() {
            return {
                selected: this.$root?.$data.currentPage ?? '',
                appList: new AppList().list
            }
        },
        mounted() {
        },
    });
