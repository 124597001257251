
    import { defineComponent } from 'vue';
    import { LaravelPaginationObject, LaravelPaginationLink } from './LaravelPagination.interface';

    export default defineComponent({
        components: {
        },
        emits: ['selected'],
        props: {
            config: Object,
        },
        data: function() {
            return {
                results: {} as LaravelPaginationObject,
                selected: {} as Object,
            }
        },
        methods: {
            selectRow(rowIndex: number, row: any) {
                const key = JSON.stringify(row);
                if (this.selected != key) {
                    this.selected = key;
                    this.$emit('selected', this.results.data[rowIndex]);
                } else {
                    this.selected = {};
                    this.$emit('selected', null);
                }
            },
            keyNotExcluded(key: string) {
                if (!this.config?.excludedKeys) {
                    return true;
                }
                return !this.config.excludedKeys.includes(key);
            },
            changePage(link: LaravelPaginationLink) {
                if (link.url && !link.active && this.config) {
                    this.config.url = link.url;
                    this.doLookup();
                }
            },
            doLookup() {
                this.$root?.$data.API.request(this.config).then((response: any) => {
                    this.results = response.data;
                })
                .catch((error: any) => {
                    this.results.data = [];
                    console.error(error);
                })
            },
            isSelected(row: any) {
                return this.selected === JSON.stringify(row);
            },
        },
        computed: {
            'rows': function() {
                if (this.results.data) {
                    if (!this.config || !this.config.excludedKeys || this.config.excludedKeys.length === 0) {
                        return this.results.data;
                    }
                    const data = JSON.parse(JSON.stringify(this.results.data));
                    return data.map((row: any) => {
                        this.config?.excludedKeys.forEach((key: string) => {
                            if (Object.keys(row).includes(key)) {
                                delete row[key];
                            }
                        });
                        return row;
                    });
                }
                return [];
            },
        },
        mounted() {
            this.doLookup();
        },
    });
