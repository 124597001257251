
    import { defineComponent } from 'vue';
    
    import ServiceTask from './ServiceTask.vue'
    import DateInput from '@/components/inputs/DateInput.vue'
    import TextInput from '@/components/inputs/TextInput.vue'
    import TextareaInput from '@/components/inputs/TextareaInput.vue'
    import NumberInput from '@/components/inputs/NumberInput.vue'
    import SearchInput from '@/components/inputs/SearchInput.vue'
    import DropdownInput from '@/components/inputs/DropdownInput.vue'
    import FieldDescription from '@/components/layout/FieldDescription.vue'
    import { Field, Hidden } from '@/modules/CreateServiceContract/helpers/Fields.interface'

    export default defineComponent({
        components: {
            ServiceTask,
            TextInput,
            TextareaInput,
            DateInput,
            NumberInput,
            SearchInput,
            DropdownInput,
            FieldDescription
        },
        props: {
            serviceContract: {
                type: Object,
                required: true
            },
        },
        data: function() {
            return {
                disabledDates: {
                    Dates: [],
                    predicate: (date: Date) => {
                        return this.onlyAllowTheFirstAndMinimum15DaysBefore(date);
                    }
                },
            }
        },
        methods: {
            fieldChanged: function(id: string) {
                if (id == 'ServiceAgreement.DiscountAmount') {
                    this.serviceContract.calculateDiscount();
                }
            },
            removeSection: function(index: number) {
                this.serviceContract.removeSection(index);
            },

            showField: function(type: string, field: Field): Boolean {
                if (field.type != type) {
                    return false;
                }
                if (field.hidden == undefined) {
                    return true;
                }

                return this.operator(field.hidden);
            },

            operator: function(hidden: Hidden) {
                const operators: any = {
                    '==': function(a: string | number, b: string | number) { return a == b },
                    '!=': function(a: string | number, b: string | number) { return a != b },
                };

                if (hidden.operator in operators)
                {
                    return !operators[hidden.operator](this.serviceContract.getFieldValue(hidden.field), hidden.value);
                }
                return false;
            },

            onlyAllowTheFirstAndMinimum15DaysBefore: function(date: Date) {
                const today = new Date();

                if (date < today) {
                    return true;
                }

                if (date.getDate() != 1) {
                    return true;
                }

                if (today.getMonth() == date.getMonth() && date.getDate() > 15) {
                    return true;
                }

                return false;
            },
        },
        computed: {
            
        },
        mounted() {
        },
        watch: {
        },
    });
