import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-52cd6a57"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "row"
}
const _hoisted_2 = { class: "col-12 text-end fst-italic" }
const _hoisted_3 = { class: "col-12" }
const _hoisted_4 = { class: "table table-sm table-striped" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "col-12" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col" }
const _hoisted_9 = { "aria-label": "navigation" }
const _hoisted_10 = { class: "pagination justify-content-center" }
const _hoisted_11 = ["disabled", "onClick", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.rows.length > 0)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.results.from) + " - " + _toDisplayString(_ctx.results.to) + " af " + _toDisplayString(_ctx.results.total) + " resultater ", 1 /* TEXT */),
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("table", _hoisted_4, [
            _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows[0], (colummn, columnIndex) => {
                  return (_openBlock(), _createElementBlock("th", { key: columnIndex }, _toDisplayString(columnIndex), 1 /* TEXT */))
                }), 128 /* KEYED_FRAGMENT */))
              ])
            ]),
            _createElementVNode("tbody", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (rowData, rowIndex) => {
                return (_openBlock(), _createElementBlock("tr", {
                  class: _normalizeClass(_ctx.isSelected(rowData) ? 'text-bg-info' : ''),
                  key: rowIndex,
                  onClick: ($event: any) => (_ctx.selectRow(rowIndex, rowData))
                }, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(rowData, (colummn, columnIndex) => {
                    return (_openBlock(), _createElementBlock("td", { key: columnIndex }, _toDisplayString(colummn), 1 /* TEXT */))
                  }), 128 /* KEYED_FRAGMENT */))
                ], 10 /* CLASS, PROPS */, _hoisted_5))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("nav", _hoisted_9, [
                _createElementVNode("ul", _hoisted_10, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.results.links, (link, linkIndex) => {
                    return (_openBlock(), _createElementBlock("li", {
                      class: "page-item",
                      key: linkIndex
                    }, [
                      _createElementVNode("button", {
                        disabled: !link.url || link.active,
                        class: _normalizeClass([link.active ? 'fw-bold' : 'text-decoration-underline', "btn btn-link"]),
                        onClick: ($event: any) => (_ctx.changePage(link)),
                        innerHTML: link.label
                      }, null, 10 /* CLASS, PROPS */, _hoisted_11)
                    ]))
                  }), 128 /* KEYED_FRAGMENT */))
                ])
              ])
            ])
          ])
        ])
      ]))
    : _createCommentVNode("v-if", true)
}