import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-063de322"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container-fluid" }
const _hoisted_2 = { class: "row justify-content-center mt-3" }
const _hoisted_3 = { class: "col-12 col-md-11 col-lg-10" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TopBar = _resolveComponent("TopBar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TopBar, {
      title: _ctx.title,
      showMenuButton: _ctx.showMenuButton,
      currentUser: _ctx.currentUser
    }, null, 8 /* PROPS */, ["title", "showMenuButton", "currentUser"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _renderSlot(_ctx.$slots, "default", {}, undefined, true)
      ])
    ])
  ]))
}