export default class AppList {
    get list(): any {
        return {
                'ax_app_suite': {
                title: 'Anticimex App Suite',
                link: '/',
                icon: 'app-indicator',
            },
                'create_servicecontract': {
                title: 'Opret Servicekontrakt',
                link: '/OpretServiceKontrakt',
                icon: 'card-checklist',
            },            
        }
    }
}