import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_picker_popup = _resolveComponent("picker-popup")!

  return (_openBlock(), _createBlock(_component_picker_popup, {
    headingClickable: "",
    leftDisabled: _ctx.leftDisabled,
    rightDisabled: _ctx.rightDisabled,
    items: _ctx.days,
    onLeft: _ctx.previousPage,
    onRight: _ctx.nextPage,
    onHeading: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back'))),
    onElementClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('select', $event)))
  }, {
    heading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.heading), 1 /* TEXT */)
    ]),
    subheading: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.weekDays, (day) => {
        return (_openBlock(), _createElementBlock("span", { key: day }, _toDisplayString(day), 1 /* TEXT */))
      }), 128 /* KEYED_FRAGMENT */))
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["leftDisabled", "rightDisabled", "items", "onLeft", "onRight"]))
}