import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c25b9880"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.appList, (data, index) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "col-3 p-3",
        key: index
      }, [
        _createVNode(_component_router_link, {
          class: "",
          to: data.link,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", {
              class: _normalizeClass(["appItem", index.toString() == _ctx.selected ? 'selected':'']),
              style: _normalizeStyle(_ctx.smallText ? 'font-size: 0.65vw': '')
            }, [
              (_openBlock(), _createBlock(_resolveDynamicComponent('b-icon-'+data.icon), { class: "appIcon" })),
              _createTextVNode(" " + _toDisplayString(data.title), 1 /* TEXT */)
            ], 6 /* CLASS, STYLE */)
          ]),
          _: 2 /* DYNAMIC */
        }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
      ]))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}