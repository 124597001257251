import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a8659fae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "progressbar" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (stepName, stepId) => {
      return (_openBlock(), _createElementBlock("li", {
        key: stepId,
        class: _normalizeClass([stepId == _ctx.currentStep ? 'active' : '', stepId <= _ctx.maxStep ? 'marked' : '']),
        style: _normalizeStyle('width: '+ (100/_ctx.steps.length) + '%'),
        onClick: ($event: any) => (_ctx.$emit('stepClicked', stepId))
      }, _toDisplayString(stepName), 15 /* TEXT, CLASS, STYLE, PROPS */, _hoisted_2))
    }), 128 /* KEYED_FRAGMENT */))
  ]))
}