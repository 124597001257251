
    import { defineComponent } from 'vue';

    export default defineComponent({
        components: {
        },
        emits: ['ok', 'cancel'],
        props: {
            text: String,
            title: String,
            okBtn: String,
            cancelBtn: String,
            enableX: {
                type: Boolean,
                default: true
            }
        },
        data: function() {
            return {
            }
        },
        mounted() {
        },
    });
