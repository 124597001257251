
    import { defineComponent } from 'vue';
    
    import StepProgess from '@/components/widgets/StepProgress.vue'
    import ConfirmDialog from '@/components/widgets/ConfirmDialog.vue'
    import SearchModal from '@/components/widgets/SearchModal.vue'
    import ServiceContract from '../helpers/ServiceContract'
    import Summary from '../components/Summary.vue'
    import Fields from '../components/Fields.vue'
    import { Column, Section } from '../helpers/Fields.interface'

    export default defineComponent({
        components: {
            StepProgess,
            ConfirmDialog,
            SearchModal,
            Summary,
            Fields
        },
        data: function() {
            return {
                serviceContract: new ServiceContract(this.$router, this.$route, this.$root?.$data.API),
                errors: false,
                ready: false,
                restoreDialog: {
                    display: false,
                    title: 'Forsæt fra hvor du slap',
                    text: 'Vil du genoptage sidste indtastning?',
                    okBtn: 'Ja',
                    enableX: false,
                    cancelBtn: 'Nej',
                    ok: () => { this.restore() },
                    cancel: () => { this.destroy() }
                },
                loadCustomerDialog: {
                    display: false,
                    title: 'Er du sikker?',
                    text: 'Hvis du indlæser en eksisterende kun vil alle ændringer vil gå tabt.',
                    okBtn: 'Ja',
                    enableX: false,
                    cancelBtn: 'Nej',
                    ok: () => { this.showImportCustomer() },
                    cancel: () => { this.hideLoadCustomerDialog() }
                },
                customerModal: {
                    display: false,
                    title: 'Find kunde',
                    searchPath: 'customer/search',
                    text: 'Søg efter eksisterende kunde',
                    okBtn: 'Vælg',
                    enableX: true,
                    excludedKeys: ['CustomerID'],
                    primaryKey: 'CustomerID',
                    cancelBtn: 'Fortryd',
                    ok: (customer: any) => { 
                        this.customerSelected(customer)
                    },
                    cancel: () => { this.hideLoadCustomerDialog() }
                },
                showImportCustomerModal: false
            }
        },
        methods: {
            autoFill: function() {
                this.serviceContract.autoFill();
            },

            goToStep: function(step: number): void {
                if (step > this.serviceContract.maxStep) {
                    return;
                }

                this.serviceContract.currentStep = step;
            },

            addSection: function() {
                this.serviceContract.addSection();
            },

            async waitForReady() {
                while(this.serviceContract.ready === false) {
                    await new Promise(resolve => setTimeout(resolve, 50));
                }

                this.ready = this.serviceContract.ready;
                this.errors = this.serviceContract.errors;

                if (this.serviceContract.restorePossible) {
                    this.restoreDialog.display = true;
                }
            },

            async previousStep() {
                this.ready = false;
                await this.serviceContract.previousStep();
                this.ready = true;
            },

            async nextStep() {
                this.ready = false;
                await this.serviceContract.nextStep();
                this.ready = true;
            },

            restore() {
                this.closeRestoreDialog();
                this.serviceContract.restore();
            },

            destroy() {
                this.closeRestoreDialog();
                this.serviceContract.destroy();
            },

            closeRestoreDialog() {
                this.restoreDialog.display = false;
            },

            importCustomer() {
                if (this.serviceContract.hasChanges) {
                    this.showLoadCustomerDialog();
                } else {
                    this.showImportCustomer();
                }
            },

            customerSelected(customer: any) {
                console.log(customer);
                this.hideImportCustomer();
            },

            showImportCustomer() {
                this.customerModal.display = true;
                this.hideLoadCustomerDialog();
            },

            hideImportCustomer() {
                this.customerModal.display = false;
            },

            hideLoadCustomerDialog() {
                console.log('Hide dialog');
                this.loadCustomerDialog.display = false;
            },

            showLoadCustomerDialog() {
                this.loadCustomerDialog.display = true;
            }
        },
        computed: {
            hasFieldErrors: function() {
                if (!this.serviceContract.hasFields) {
                    console.log('No fields');
                    return false;
                }
                if (!this.ready) {
                    return true;
                }
                console.log('Checking for errors');
                let hasErrors = false;

                if (this.serviceContract.canBeArray)
                {
                    this.serviceContract.values.forEach((value, index) => {
                        value.forEach((section: Section) => {
                            section.columns.forEach(column => {
                                if (checkColumnForErrors(column)) {
                                    return true;
                                }
                            });
                        });
                    });
                } else {
                    this.serviceContract.fields.forEach(section => {
                        section.columns.forEach(column => {
                            if (checkColumnForErrors(column)) {
                                return true;
                            }
                        });
                    });
                }

                function checkColumnForErrors(column: Column) {
                    column.fields.forEach(field => {
                        if (field.errors  == true) {
                            hasErrors = true;
                            console.log('Error', field);
                            return true;
                        } else if (/required/.test(field.rules ?? '') && field.value == '') {
                            hasErrors = true;
                            console.log('Error', field);
                            return true;
                        }
                    });
                    return false;
                }

                return hasErrors;
            }
        },
        mounted() {
            this.waitForReady();
        },
        watch: {
        },
    });
