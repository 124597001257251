import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_picker_popup = _resolveComponent("picker-popup")!

  return (_openBlock(), _createBlock(_component_picker_popup, {
    headingClickable: "",
    columnCount: 3,
    items: _ctx.months,
    leftDisabled: _ctx.leftDisabled,
    rightDisabled: _ctx.rightDisabled,
    onLeft: _ctx.previousPage,
    onRight: _ctx.nextPage,
    onHeading: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('back'))),
    onElementClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('select', $event)))
  }, {
    heading: _withCtx(() => [
      _createTextVNode(_toDisplayString(_ctx.heading), 1 /* TEXT */)
    ]),
    _: 1 /* STABLE */
  }, 8 /* PROPS */, ["items", "leftDisabled", "rightDisabled", "onLeft", "onRight"]))
}